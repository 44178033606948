<template>
  <div class="my-projects">
    <div class="title">
      <div class="orange"></div>
      <div class="title-content">
        <span class="our">BUSINESS</span>
        <span class="projects">SEGMENTS</span>
      </div>
    </div>
    <div class="content">
      <div class="cart-item" v-for="(item, idx) in lists" :key="item.id">
        <div
          class="item"
          :class="cardMouseEnter === idx ? 'item-mouse-enter' : ''"
          @mouseenter="cardMouseEnter = idx"
          @mouseleave="cardMouseEnter = null"
          @click="routeServer(item.path)"
        >
          <div class="name" v-show="cardMouseEnter != idx">
            {{ item.title }}
          </div>
          <div class="des" v-show="cardMouseEnter != idx">{{ item.des }}</div>
          <div class="enter-text" v-show="cardMouseEnter == idx">
            {{ item.enterTitle }}
          </div>
          <div class="orange"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyProjects",
  data() {
    return {
      lists: [
        {
          id: 0,
          title: "地产开发",
          des: "DEVELOPMENT",
          enterTitle: "科技赋能，智慧人居",
          path: 'business/dwell'
        },
        {
          id: 1,
          title: "商业运营",
          des: "OPERATION",
          enterTitle: "赋能城市，畅享繁华",
          path: 'business/operate'
        },
        {
          id: 2,
          title: "物业服务",
          des: "PROPERTY",
          enterTitle: "以爱相伴，情暖一生",
          path: 'business/estate'
        },
      ],
      // 鼠标当前进入的 card(class名称)
      cardMouseEnter: null,
    };
  },
  methods: {
    routeServer(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped lang="less">
.my-projects {
  width: 100%;
  height: 642px;
  background: url(../../assets/images/home-back.png) no-repeat center/cover;
  padding: 135px 160px;
  .title {
    font-size: 52px;
    position: relative;
    height: 50px;
    font-family: "DIN Alternate Bold";
    .title-content {
      position: absolute;
      z-index: 2;
      top: -5px;
      left: 2px;
      line-height: 60px;
      .our {
        color: #000;
        margin-right: 10px;
        z-index: 9;
      }
      .projects {
        color: #fff;
      }
    }

    .orange {
      width: 80px;
      height: 24px;
      position: absolute;
      background: #ee6a09;
      top: 0;
      z-index: 1;
    }
  }
  .content {
    width: 100%;
    height: 300px;
    margin-top: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cart-item {
      width: 33.3%;
      // height: 180px;
      flex-grow: 0;
      flex-shrink: 0;
      // display: inline-block;
      padding: 20px 0;
      .item {
        // background-color: #fff;
        position: relative;
        background: rgba(255, 255, 255, 0.6);
        width: 510px;
        height: 224px;
        padding: 57px 45px;
        cursor: pointer;
        color: #ee6a09;
        border-radius: 10px;
        transition: 0.3s all;
        font-family: "苹方-简 中黑体";
        src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
        .name {
          font-size: 42px;
          font-weight: 600;
        }
        .des {
          font-size: 36px;
          opacity: 0.6;
        }
        .orange {
          width: 20px;
          height: 6px;
          background-color: #ee6a09;
          position: absolute;
          right: 20px;
          bottom: 20px;
          transition: 0.3s all;
        }
        .enter-text {
          color: #fff;
          font-size: 42px;
          font-weight: 600;
        }
        &.item-mouse-enter {
          background: #ee6a09;
          .orange {
            background: #fff;
          }
        }
      }
    }
  }
}
</style>
