<template>
  <div class="my-carousel">
    <div class="circle">
      <div
        class="circle-item"
        v-for="(item, index) in photo"
        :key="item.id"
        :class="{ active: item.id == activeid }"
        @click="setActiveItem(index)"
      ></div>
    </div>
    <el-carousel
      height="960px"
      arrow="never"
      indicator-position="none"
      @change="changeImg"
      ref="carousel"
    >
      <el-carousel-item v-for="item in photo" :key="item.id">
        <div class="el-carousel-img">
          <img :src="item.photo" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    photo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeid: 0,
    };
  },
  mounted() {},

  methods: {
    changeImg(val) {
      this.activeid = val;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
  },
};
</script>

<style scoped lang="less">
.my-carousel {
  width: 100%;
  height: 960px;
  overflow: hidden;
  position: relative;
  .circle {
    position: absolute;
    top: 422px;
    right: 160px;
    z-index: 9;
    .circle-item {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #fff;
      margin-bottom: 30px;
    }
    .active {
      background-color: #666;
    }
  }
  .el-carousel__item {
    width: 100%;
    height: 960px;
    .el-carousel-img {
      width: 100%;
      height: 960px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  // .carousel {
  //   width: 100%;
  //   height: 100%;
  //   overflow: hidden;
  //   .carousel-inner {
  //     width: 100%;
  //     height: 100%;
  //     overflow: hidden;
  //     .carousel-item {
  //       width: 100%;
  //       height: 100%;
  //       overflow: hidden;
  //       img {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }
  //   }
  // }
}
</style>
