<template>
  <div class="home-content">
    <my-carousel :photo="photo" />
    <my-news :media="media" :newsLists="newsLists" />
    <my-projects />
  </div>
</template>
<script>
import { apiGetHomeData } from "../../api/index";
import MyCarousel from "../../components/home/MyCarousel.vue";
import MyNews from "../../components/home/MyNews.vue";
import MyProjects from "../../components/home/MyProjects.vue";
export default {
  components: {
    MyCarousel,
    MyNews,
    MyProjects,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      newsLists: [],
      media: [],
      photo: [],
    };
  },
  methods: {
    init() {
      apiGetHomeData().then((data) => {
        if (data.data.status == 1) {
          this.media = data.data.news["media"];
          this.newsLists = data.data.news["new"];

          this.media.forEach((item) => {
            item.time = this.forMatter((item.create_time + "000") * 1);
          });
          this.newsLists.forEach((item) => {
            item.time = this.forMatter((item.create_time + "000") * 1);
          });
          data.data.photo.forEach((item) => {
            item.id = item.id * 1 - 1;
            item.photo = "http://www.weaiove.com" + item.photo;
          });
          this.photo = data.data.photo;
        }
      });
    },

    forMatter(d) {
      var date = new Date(d);
      var getyear = date.getFullYear();
      var getmonth =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      var getday = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();

      return getyear + "." + getmonth + "." + getday;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped></style>
