<template>
  <div class="my-news">
    <div class="lists">
      <div class="tabs">
        <div
          class="news"
          v-for="(item, index) in news"
          :key="index"
          :class="{ active: activeId === item.id }"
          @click="changeTag(item.id)"
        >
          {{ item.name }}
        </div>
        <div class="news-img">
          <img src="../../assets/images/news.png" />
        </div>
      </div>
      <div class="news-lists" v-if="activeId === 0">
        <div
          class="list-item"
          v-for="item in newsLists"
          :key="item.id"
          @click="newsRoute(item)"
        >
          <span class="time"> {{ item.time }}</span>
          <span class="title"> {{ item.laiyuan }} | {{ item.title }}</span>
        </div>
        <div class="check-more" @click="checkMore('GroupNews')">
          <span>查看更多</span>
          <img src="../../assets/images/more.png" alt="more" />
        </div>
      </div>
      <div class="news-lists" v-else>
        <div
          class="list-item"
          v-for="item in media"
          :key="item.id"
          @click="newsRoute(item)"
        >
          <span class="time"> {{ item.time }}</span>
          <span class="title">{{ item.laiyuan }} | {{ item.title }}</span>
        </div>
        <div class="check-more" @click="checkMore('MediaReport')">
          <span>查看更多</span>
          <img src="../../assets/images/more.png" alt="more" />
        </div>
      </div>
    </div>
    <div class="pic">
      <div
        class="pic-content"
        :class="picMouseEnter ? 'mouse-enter' : ''"
        @mouseenter="picMouseEnter = true"
        @mouseleave="picMouseEnter = false"
        @click="routePic"
      >
        <div class="es">
          Strategic Layout
        </div>
        <div class="zh">
          战略布局
        </div>
        <div class="pic-enter" v-show="picMouseEnter">
          <div>爱情地产战略布局京津冀城市群、长三角城市群、华中城市群、成渝城市群、粤港澳大湾区五大城市群的21个强二线城市</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyNews",
  props: {
    newsLists: {
      type: Array,
      default: () => [],
    },
    media: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeId: 0,
      news: [
        {
          id: 0,
          name: "集团新闻",
        },
        {
          id: 1,
          name: "媒体报道",
        },
      ],
      // 鼠标是否进入 pic(class名称)
      picMouseEnter: false,
    };
  },
  methods: {
    newsRoute(item) {
      this.$router.push({
        name: "NewsDetails",
        params: {
          id: item.id,
        },
      });
    },
    routePic() {
      this.$router.push({
        path: 'company/layout'
      })
    },
    changeTag(id) {
      this.activeId = id;
    },
    checkMore(name) {
      this.$router.push({
        name: name,
      });
    },
  },
};
</script>

<style scoped lang="less">
.my-news {
  width: 100%;
  // height: 420px;
  position: relative;
  padding: 0 150px 20px;
  margin-top: 103px;
  .lists {
    // width: 55%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    .tabs {
      height: 50px;
      border-bottom: 2px solid #f6f6f6;
      color: #666;
      position: relative;
      .news-img {
        width: 324px;
        height: 94px;
        overflow: hidden;
        position: absolute;
        top: -45px;
        left: -110px;
        z-index: -1;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .news {
        width: 124px;
        height: 52px;
        text-align: center;
        display: inline-block;
        text-align: center;
        font-size: 30px;
        cursor: pointer;
        margin-right: 58px;
        font-family: "苹方-简 中黑体";
        src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
      }
      .active {
        border-bottom: 2px solid #e95506;
        color: #222;
        font-weight: 600;
      }
    }
    .news-lists {
      padding: 46px 0;
      .list-item {
        // line-height: 35px;
        font-size: 20px;
        margin-bottom: 20px;
        color: #666;
        cursor: pointer;
        font-family: "苹方-简 常规体";
        src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
        // border-bottom: 1px solid red;
        .time {
          display: inline-block;
          width: 107px;
          height: 28px;
          margin-right: 66px;
          vertical-align: top;
          // padding-top: 4px;
          // padding-left: 10px;
        }
        .title {
          display: inline-block;
          text-align: left;
          width: 460px;
          height: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .list-item:hover {
        color: #e95506 !important;
      }
      .check-more {
        font-family: "苹方-简 中黑体";
        src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
        cursor: pointer;
        font-size: 18px;
        margin-top: 6px;
        line-height: 30px;
        span {
          vertical-align: top;
          margin-right: 9px;
          display: inline-block;
          width: 80px;
        }
        img {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-top: -3px;
        }
      }
    }
  }
  .pic {
    width: 741px;
    height: 100%;
    display: inline-block;
    padding: 0;
    margin-left: 224px;
    overflow: hidden;
    .pic-content {
      width: 100%;
      height: 350px;
      background: url(../../assets/images/orange-back.png) no-repeat
        center/cover;
      padding: 105px 48px;
      border-radius: 6px;
      color: #fff;
      cursor: pointer;
      transition: 0.3s all;
      font-family: "苹方-简 中黑体";
      src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
      &.mouse-enter {
        padding: 64px 48px;
      }
      .es {
        font-size: 30px;
        opacity: 0.6;
      }
      .zh {
        font-size: 42px;
        // font-weight: 500;
      }
      .pic-enter {
        margin-top: 20px;
        font-size: 26px;
      }
    }
  }
}
</style>
