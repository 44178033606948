<template>
    <div class="statement">
        <div class="kong"></div>
        <div class="imgBox">
          <img src='../../assets/images/news-header.png'>
        </div>
        <property-title :backgroundTitle="backgroundTitle" :title="title" ></property-title>

        <div class="content">
          <p>本网站(www.weaiove.com)由保定市爱情地产集团有限公司（简称“爱情地产集团”）创设。本网站所载内容版权归属爱情地产集团所有或与本网站所载内容作者共同所有，在此并未作任何授权。本网站上的文件仅限于为信息展示和非商业或个人之目的使用，并且不得在任何网络计算机上复制或公布，也不得在任何媒体上传播。未经版权人许可，任何人不得擅自(包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载)使用。否则，将构成对版权人的侵权，应依法承担相应法律责任。</p>
          <!-- <p>免责声明版权人拥有对本网站的内容进行随时更改之权利，事前将不会另行通知。版权人不保证或声明本网站展示的资料是否正确、全面或是最新数据。</p>
          <p>本网站内的所有图片(包括但不限于插图、透视图、设计图等)、模型、文字描述等，仅作为参考信息，非开发商的任何承诺或要约。 开发商声明网站有关其开发项目信息的介绍，均以政府最后审批文件为准。商品房买卖双方之间的关系以当事人签订的买卖双方之间的关系以当事人签订买卖合同为准。</p>
          <p>任何人因本网站或依赖其内容进行交易所引致的损失，版权人和开发商均不承担任何责任。</p> -->
        </div>
        <property-title :title="title1" ></property-title>
        <div class="content">
          <p>爱情地产集团拥有对本网站的内容进行随时更改之权利，事前将不会另行通知。除法律另有规定外，爱情地产集团不保证或声明本网站展示的资料是否正确、全面或是最新数据。</p>
          <p>本网站相关资料所展示地图等信息，仅用于展示爱情地产集团项目所进入的城市群，而非完整的地理及行政区划信息。</p>
          <p>本网站内的所有图片(包括但不限于插图、透视图、设计图等)、模型、文字描述等，仅作为参考信息，不构成任何销售、服务、授权、承诺、保证或要约等。 
对于本网站所载信息，使用者应自行判断和决策是否信任并使用，任何人因本网站或依赖其内容进行交易所引致的损失，爱情地产集团及其任何关联公司均不承担任何法律责任。</p>
        </div>
        <property-title :title="title2" ></property-title>
        <div class="content">
          <p>本网站有关其开发项目信息的介绍，均以政府最后审批文件为准。买卖双方之间的权利义务关系以当事人签订的商品房买卖合同为准。</p>
        </div>
    </div>
</template>
<script>
import PropertyTitle from '../../components/common/PropertyTitle.vue'

export default {
  components: {
    PropertyTitle,
    
  },
  data() {
    return {
      backgroundTitle: "STATEMENT",
      title: "法律声明",
      title1:"免责声明",
      title2:"开发商声明"
    };
  },
};
</script>
<style scoped lang='less'>
.statement{
  .kong{
    width: 100%;
    height: 140px;
  }
  .imgBox{
    width: 100%;
    height: 200px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .content{
    max-width: 1400px;
    margin:10px auto 200px;
    padding-top: 50px;
    color: #666;
    font-size: 22px;
    line-height: 40px;
    font-family: '苹方-简 常规体';
    text-align: left;
    p{
      margin-bottom: 10px !important;
    }
  }
}
</style>